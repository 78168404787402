export const generatePassword = (length = 8) => {
  const characters =
    "@#$&%!123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz123456789@#$&%!";
  let password = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * (characters.length - 1));
    const randomChar = characters[randomIndex];
    password += randomChar;
  }
  return password;
};

export const generatePasswordSimple = (length = 8) => {
  const characters =
    "0123456789ABCDEFGHJKLMNOPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz0123456789";
  let password = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * (characters.length - 1));
    const randomChar = characters[randomIndex];
    password += randomChar;
  }
  return password;
};

export const roundToTwo = (number, multi = 100) => {
  const lengthNumber = (String(multi).match(/0/g) || []).length;
  const multiplier = multi; // 10^n
  const roundedNumber = Math.round(Number(number) * multiplier) / multiplier;
  const formattedNumber = number !== null && number !== undefined ? roundedNumber.toFixed(lengthNumber) : null;
  return formattedNumber;
};

export const roundToTwoInput = (number, rounding) => {
  let paddedNum = rounding ? Number(String(1).padEnd(rounding+1, "0")) : 100; 
  const roundedNumber = Math.round(Number(number) * paddedNum) / paddedNum;
  return roundedNumber;
};

export const roundToSix = (number, test = "false") => {
  const multiplier = 1000000; // 10^n
  const roundedNumber = Math.round(Number(number) * multiplier) / multiplier;
  return roundedNumber;
};

export const formatNumber = (number, rounding=100, length=2) => {
  const roundedNumber = Math.round(Number(number) * rounding) / rounding;
  const roundedNumberResult = number !== null && number !== undefined ? roundedNumber.toFixed(length) : null;
  // Replace the dot with a comma
  const numberComma = roundedNumberResult?.replace('.', ',');
  // Add space as the thousands separator
  const formattedValue = numberComma?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return formattedValue
};

export const commaNumber = (number, rounding=100, length=2) => {
  const roundedNumber = Math.round(Number(number) * rounding) / rounding;
  const roundedNumberResult = number !== null && number !== undefined ? roundedNumber.toFixed(length) : null;
  // Replace the dot with a comma
  const numberComma = roundedNumberResult?.replace('.', ',');
  
  return numberComma
};

export const checkValidObject = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (error) {
    return null;
  }
};

/*hexToRGBA('#fff')   ------>  rgba(255,255,255,1)  
hexToRGBA('#ffffff')  ------>  rgba(255,255,255,1)  
hexToRGBA('#fff', .2) ------>  rgba(255,255,255,0.2)*/

export const hexToRGBA = (hex, opacity) => {
  return (
    "rgba(" +
    (hex = hex.replace("#", ""))
      .match(new RegExp("(.{" + hex.length / 3 + "})", "g"))
      .map(function (l) {
        return parseInt(hex.length % 2 ? l + l : l, 16);
      })
      .concat(isFinite(opacity) ? opacity : 1)
      .join(",") +
    ")"
  );
};
