import { createContext, useRef, useState } from "react";
import { checkValidObject } from "utils/utility";

// create context
export const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  //Address header
  const [addressID, setAddressID] = useState(localStorage.getItem("AddressID"));
  const [companySelected, setCompanySelected] = useState(false);
  const [selectedIkr, setSelectedIkr] = useState(null);
  const [heatReadBru, setHeatReadBru] = useState(0);
  const [heatBillBru, setHeatBillBru] = useState(12.3);
  const [heatingCostsBrutto, setHeatingCostsBrutto] = useState();
  const [consCWU, setConsCWU] = useState(null);
  const [selectedType, setSelectedType] = useState();
  const scrollPosition = useRef(0);
  const [addressIDCost, setAddressIDCost] = useState();
  const [top, setTop] = useState(true);
  const [headerTop, setHeaderTop] = useState(false);

  //For prompts
  const [openPromptModal, setOpenPromptModal] = useState(false);
  const [promptField, setPromptField] = useState("");
  const [promptServiceID, setPromptServiceID] = useState(null);

  const [addressModalOpen, setAddressModalOpen] = useState(false);

  const [billingID, setBillingID] = useState("");
  const [billingData, setBillingData] = useState([]);
  const [tempId, setTempId] = useState();
  const [selectedComponent, setSelectedComponent] = useState("Panel");

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenExist, setIsModalOpenExist] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [resultsPerPage, setResultsPerPage] = useState(100);
  const [limitData, setLimitData] = useState(12);
  const [isBulkDrawerOpen, setIsBulkDrawerOpen] = useState(false);
  const [lang, setLang] = useState("");
  const [time, setTime] = useState("");

  const [modulesAll, setModulesAll] = useState();
  const [mediumsAll, setMediumsAll] = useState();

  const [dateType, setDateType] = useState("all");

  const [sortedField, setSortedField] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState(null);
  const [zone, setZone] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [date, setDate] = useState(null);
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(
    checkValidObject("AddressInfo")?.systemType?.toLowerCase() ===
      "inkasencki cieslanet+"
      ? "reads"
      : "days"
  );
  const [medium, setMedium] = useState(null);
  const [status, setStatus] = useState("");
  const [refresh, setRefresh] = useState(0);
  const [source, setSource] = useState("");
  const [category, setCategory] = useState(null);
  const [drawerType, setDrawerType] = useState();
  const searchRef = useRef("");

  const closeSidebar = () => setIsSidebarOpen(false);
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const closeDrawer = () => setIsDrawerOpen(false);
  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

  const closeModalExist = () => {
    setIsModalOpenExist(false);
  };
  const toggleModalExist = () => {
    setIsModalOpenExist(!isModalOpenExist);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const closeBulkDrawer = () => setIsBulkDrawerOpen(false);
  const toggleBulkDrawer = () => setIsBulkDrawerOpen(!isBulkDrawerOpen);

  const handleChangePage = (p) => {
    setCurrentPage(p);
  };

  const handleSubmitForAll = (e) => {
    e.preventDefault();
    if (!searchRef.current.value) return setSearchText(null);
    setCategory(null);
    setSearchText(searchRef.current.value);
  };

  // const value = useMemo(
  //   () => ({
  //     isSidebarOpen,
  //     toggleSidebar,
  //     closeSidebar,
  //     isDrawerOpen,
  //     toggleDrawer,
  //     closeDrawer,
  //     setIsDrawerOpen,
  //     isModalOpen,
  //     toggleModal,
  //     closeModal,
  //     isUpdate,
  //     setIsUpdate,
  //   }),

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [isSidebarOpen, isDrawerOpen, isModalOpen, isUpdate]
  // );

  return (
    <SidebarContext.Provider
      value={{
        isSidebarOpen,
        toggleSidebar,
        closeSidebar,
        isDrawerOpen,
        toggleDrawer,
        closeDrawer,
        setIsDrawerOpen,
        isModalOpen,
        toggleModal,
        closeModal,
        isUpdate,
        setIsUpdate,
        closeBulkDrawer,
        isBulkDrawerOpen,
        toggleBulkDrawer,
        lang,
        setLang,
        currentPage,
        setCurrentPage,
        handleChangePage,
        searchText,
        setSearchText,
        category,
        setCategory,
        searchRef,
        handleSubmitForAll,
        status,
        setStatus,
        refresh,
        setRefresh,
        zone,
        setZone,
        time,
        setTime,
        sortedField,
        setSortedField,
        source,
        setSource,
        resultsPerPage,
        setResultsPerPage,
        limitData,
        setLimitData,
        closeModalExist,
        toggleModalExist,
        isModalOpenExist,
        addressID,
        setAddressID,
        companySelected,
        setCompanySelected,
        scrollPosition,
        setTop,
        top,
        headerTop,
        setHeaderTop,
        openPromptModal,
        setOpenPromptModal,
        promptField,
        setPromptField,
        promptServiceID,
        setPromptServiceID,
        selectedIkr,
        setSelectedIkr,
        heatReadBru,
        setHeatReadBru,
        heatBillBru,
        setHeatBillBru,
        heatingCostsBrutto,
        setHeatingCostsBrutto,
        consCWU,
        setConsCWU,
        selectedType,
        setSelectedType,
        addressIDCost,
        setAddressIDCost,
        addressModalOpen,
        setAddressModalOpen,
        setBillingID,
        billingID,
        setBillingData,
        billingData,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        date,
        setDate,
        user,
        setUser,
        profile,
        setProfile,
        dateType,
        setDateType,
        medium,
        setMedium,
        tempId,
        setTempId,
        drawerType,
        setDrawerType,
        modulesAll,
        mediumsAll,
        setModulesAll,
        setMediumsAll,
        selectedComponent,
        setSelectedComponent,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
