import React from "react";
import ScaleLoader from "react-spinners/ScaleLoader";

const ThemeSuspense = () => {
  return (
    <div className="flex justify-center items-center w-full h-screen p-6 text-lg dark:bg-cieslaGranat"></div>
  );
};

export default ThemeSuspense;
