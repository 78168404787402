import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ToastContainer } from "./utils/toast";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import PrivateRoute from "./components/login/PrivateRoute";
import ThemeSuspense from "./components/theme/ThemeSuspense";

// ** Config Imports
import "./configs/i18n";

const Layout = lazy(() => import("./layout/Layout"));
const Login = lazy(() => import("./pages/Login"));
const SignUp = lazy(() => import("./pages/SignUp"));
const ForgetPassword = lazy(() => import("./pages/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const App = () => {
  return (
    <>
      <Suspense fallback={<ThemeSuspense />}>
        <ToastContainer />
        <Router basename="/">
          <AccessibleNavigationAnnouncer />
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/signup" component={SignUp} />
            <Route path="/forgot-password" component={ForgetPassword} />
            <Route path="/reset-password/:token" component={ResetPassword} />

            <PrivateRoute>
              <Route path="/" component={Layout} />
            </PrivateRoute>
            <Redirect exact from="/" to="/login" />
          </Switch>
        </Router>
      </Suspense>
    </>
  );
};

export default App;
